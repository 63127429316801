<template>
	<t-icon-button @click="$emit('clicked')"
     :class="['absolute top-1/2 transform -translate-y-1/2 right-3 hover:opacity-70 hover:text-redAccentLight cursor-pointer', classes]">
		<base-icon v-if="show" class="h-2.5 w-2.5" color="fill-black hover:fill-redAccentLight" name="x">
		</base-icon>
	</t-icon-button>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon.vue";

export default {
	name: "CloseButton",
	components: {BaseIcon},
	props: {
		show: Boolean,
		classes: String,
	}
}
</script>
